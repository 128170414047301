import { baseApiAxios, showError } from './js/portal/api';
import {
  addToast,
  hasAccess,
  loadRegionsAndUpdateDropdown,
  showAndProcessRegionRestrictWarning,
  validateAcknowledgeDeniedRegion,
} from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { centralFillVPCs, centralLoadVPCs } from './js/portal/networks';
import AccountDropdown from './jsx/components/forms/AccountDropdown';
import swal from 'sweetalert';

export default function NetworksPeerPage() {
  let userAccessAccountA, userAccessAccountB;

  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="network-peer-form" id="network-peer-form">
          <div class="card form-card">
            <div class="card-header">Create New VPC Peering</div>
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              <div id="infobox" class="card infobox">
                <div class="card-body">
                  With this form you can request a peering between two networks. For more information please have a look
                  in our{' '}
                  <a
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/network/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Portal
                    <i class="px-1 fas fa-external-link-alt" />
                  </a>
                  .
                </div>
              </div>

              <div class="row row-cols-1 row-cols-xl-2 gy-3">
                <div class="col">
                  {/* NETWORK A */}
                  <div class="card card-success">
                    <div class="card-header bg-primary text-white">
                      Network A <small>(VPC Peering Requester)</small>
                    </div>

                    <div class="card-body">
                      <div class="form-group row">
                        <label for="vpc_peering_requester_vpc_owner_id" class="col-form-label">
                          AWS Account ID
                        </label>
                        <div class="col-md-12 col-lg-10 col-xl-10 my-auto">
                          <AccountDropdown
                            label="none"
                            id="aws-account-id-a"
                            onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                              document.getElementById('submitButton').disabled =
                                !userAccessAccountB && !hasAccess(accountId, ['manage_networks']);
                              loadRegionsAndUpdateDropdown('public', ['region-a']);
                              checkAccountType('region-a', 'aws-account-id-a', centralFillVPCs, {
                                listId: 'network-id-a',
                                disableSharedVPCs: true,
                              });
                            }}
                            short
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label for="region-a" class="col-form-label">
                          Region
                        </label>
                        <div class="col">
                          <select
                            required
                            class="form-control form-select selectpicker"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="region-a"
                            name="region"
                            onchange={async () => {
                              showAndProcessRegionRestrictWarning(
                                'region-a',
                                'acknowledge-denied-region',
                                'selected-denied-region-a',
                              );
                              centralLoadVPCs('region-a', 'aws-account-id-a', centralFillVPCs, {
                                listId: 'network-id-a',
                                disableSharedVPCs: true,
                              });
                            }}
                          />
                        </div>
                      </div>

                      {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX START */}
                      <input type="checkbox" id="selected-denied-region-a" name="selected-denied-region-a" hidden />
                      {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX END */}

                      <div class="form-group row">
                        <label for="network-id-a" class="col-form-label">
                          Network ID
                        </label>
                        <div class="col-md-12 col-lg-10 col-xl-10 my-auto">
                          <select
                            required
                            class="form-control form-select selectpicker vpc-dropdown"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="network-id-a"
                            name="network-id-a">
                            <option data-type="defaultDisabled" value="" disabled selected>
                              - Select a Network ID -
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  {/* NETWORK B */}
                  <div class="card card-info">
                    <div class="card-header bg-secondary text-white">
                      Network B <small>(VPC Peering Accepter)</small>
                    </div>

                    <div class="card-body">
                      <div class="form-group row">
                        <label for="vpc_peering_requester_vpc_owner_id" class="col-form-label">
                          AWS Account ID
                        </label>
                        <div class="col-md-12 col-lg-10 col-xl-10 my-auto">
                          <AccountDropdown
                            label="none"
                            id="aws-account-id-b"
                            onchange={(_eventTypeId, _eventCategory, _selectedOption, accountId) => {
                              document.getElementById('submitButton').disabled =
                                !userAccessAccountA && !hasAccess(accountId, ['manage_networks']);
                              loadRegionsAndUpdateDropdown('public', ['region-b']);
                              checkAccountType('region-b', 'aws-account-id-b', centralFillVPCs, {
                                listId: 'network-id-b',
                                disableSharedVPCs: true,
                              });
                            }}
                            short
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label for="region-b" class="col-form-label">
                          Region
                        </label>
                        <div class="col">
                          <select
                            required
                            class="form-control form-select selectpicker"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="region-b"
                            name="region"
                            onchange={async () => {
                              showAndProcessRegionRestrictWarning(
                                'region-b',
                                'acknowledge-denied-region',
                                'selected-denied-region-b',
                              );
                              centralLoadVPCs('region-b', 'aws-account-id-b', centralFillVPCs, {
                                listId: 'network-id-b',
                                disableSharedVPCs: true,
                              });
                            }}
                          />
                        </div>
                      </div>

                      {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX START */}
                      <input type="checkbox" id="selected-denied-region-b" name="selected-denied-region-b" hidden />
                      {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX END */}

                      <div class="form-group row">
                        <label for="network-id-b" class="col-form-label">
                          Network ID
                        </label>
                        <div class="col-md-12 col-lg-10 col-xl-10 my-auto">
                          <select
                            required
                            class="form-control form-select selectpicker vpc-dropdown"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="network-id-b"
                            name="network-id-b">
                            <option data-type="defaultDisabled" value="" disabled selected>
                              - Select a Network ID -
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX START */}
            <input type="checkbox" id="acknowledge-denied-region" name="acknowledge-denied-region" hidden />
            {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX END */}

            <div class="card-footer" align="right">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="create-peering" />
              <input type="hidden" name="jobTypeDescription" id="jobTypeDescription" value="Create VPC Peering" />
              <button type="submit" class="btn btn-success" id="submitButton" value="Create VPC Peering">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                Create VPC Peering
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  /**
   * Only valid account types can order a VPC peering. This function will verify if the selected accounts and their
   * account types can order a peering.
   */
  function checkAccountTypes() {
    addSpinner();
    addButtonSpinner();
    return new Promise((resolve, reject) => {
      // Retrieve the network settings from the backend, which contains the valid account types for peering
      // my suggestion would be to define models/contracts between frontend and backend
      // and not read directly from the DB in the backend
      baseApiAxios.getSettingsByKey(['networking']).then(settings => {
        removeSpinners();
        const peerings_account_types = settings.networking.vpc_validation.peerings_account_types || [];

        // Check both account dropdowns and their account types
        ['#aws-account-id-a', '#aws-account-id-b'].forEach(listId => {
          const accounts = $(listId).find('option:selected');
          accounts.each(id => {
            const accountId = $(accounts[id]).attr('value');
            const accountType = $(accounts[id]).attr('account_type');

            // Show an error and reject the promise if the account type is not allowed to create VPC peerings
            if (accountType && !peerings_account_types.includes(accountType)) {
              const errorContent = document.createElement('div');
              errorContent.innerHTML = `VPC Peerings for account <strong>${accountId}</strong> are not allowed because the account type is "<strong>${accountType}</strong>".`;
              swal({
                title: 'Error:',
                content: errorContent,
                icon: 'error',
              });
              reject(`VPC Peerings with Account Types "${accountType}" are not allowed.`);
            }
          });
        });
        resolve();
      });
    });
  }

  /**
   * Check the account types if they are allowed to order VPC peerings. Reload the regions if the selected account
   * types are valid.
   */
  function checkAccountType(listId, regionId, accountId, callback) {
    checkAccountTypes()
      .then(() => {
        centralLoadVPCs(regionId, accountId, callback, { listId: listId });
      })
      .catch(error => console.error(error));
  }

  function onPageReady() {
    loadRegionsAndUpdateDropdown('public', ['region-a', 'region-b']);

    $('#network-peer-form')
      .on('submit', function (e) {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
        addSpinner();
        addButtonSpinner();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        submitHandler: function () {
          if ($('#network-id-a').val() === $('#network-id-b').val()) {
            $('#form-error').html('<strong>Warning: </strong> Please select two different VPCs.');
            $('#form-error').show();
            return;
          }

          checkAccountTypes()
            .then(() => {
              // validatte if the user has acknowledged the denied regions
              // otherwise the order will not be submitted
              return validateAcknowledgeDeniedRegion(
                'acknowledge-denied-region',
                document.getElementById('selected-denied-region-a').checked ||
                  document.getElementById('selected-denied-region-b').checked,
              );
            })
            .then(() => {
              const orderPayload = {
                action: $('#jobType')[0].value,
                description: $('#jobTypeDescription')[0].value,
                region_a: $('#region-a')[0].value,
                account_id_a: $('#aws-account-id-a')[0].value.split(';')[0],
                vpc_id_a: $('#network-id-a')[0].value,
                region_b: $('#region-b')[0].value,
                account_id_b: $('#aws-account-id-b')[0].value.split(';')[0],
                vpc_id_b: $('#network-id-b')[0].value,
                acknowledge_denied_region: document.getElementById('acknowledge-denied-region').checked,
              };

              baseApiAxios
                .createOrder(orderPayload)
                .then(orderResponse => {
                  addToast('Create Order', orderResponse.message, 6000);
                  removeSpinners();
                })
                .catch(orderError => {
                  showError(orderError);
                  removeSpinners();
                });
            })
            .catch(error => {
              console.error(error);
              showError(error);
              removeSpinners();
            });
        },
      });
  }

  return [page, onPageReady];
}
